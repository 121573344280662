import React, { useState, useEffect } from "react";
import { QRCode } from "react-qrcode-logo";
import { useParams } from "react-router-dom";
import sjcl from "sjcl";
import FrontendConfig from "../assets/config.js";
import NormalView from "./templates/NormalView";
//import ReactPinField from "react-pin-field";
import PINCode from "./templates/PINCode";
import {
  base64ToArrayBuffer,
  decrypt,
  isJson,
  resolveCredentialsLinkResponse,
  resolveCustomLinkResponse,
  resolveFileResponse,
  resolveMessageLinkResponse,
  resolvePasswordLinkResponse,
  b64encode,
  b64decode
} from "./Utils";

import { viewDetailsConfig } from "./config";

function ViewDetailsDefault({ secretPart, toggleShowModal, showOwnerLink }) {
  const envConfig = viewDetailsConfig();
  const { id, password, pin } = useParams();

  const [view, setView] = useState("");
  const [data, setData] = useState({});
  const [secretData, setSecretData] = useState("");
  const [decryptPassword, setDecryptPassword] = useState("");

  const fullId = secretPart + id;
  let viewPin = false;
  let viewNormal = false;

  const pinRef = React.createRef();

  let typePIN = "number";
  if (pin == "pin") {
    typePIN = "password";
  }

  const cleanState = function(){
    setView("");
    setData({});
    setSecretData("");
    setDecryptPassword("");
    showOwnerLink(true);
  };

  /*
   *  Creates a progressbar.
   *  @param id the id of the div we want to transform in a progressbar
   *  @param duration the duration of the timer example: '10s'
   *  @param callback, optional function which is called when the progressbar reaches 0.
   */
  function createProgressbar(id, duration, callback) {
    // We select the div that we want to turn into a progressbar
    var progressbar = document.getElementById(id);
    progressbar.className = "progressbar-timer";

    // We create the div that changes width to show progress
    var progressbarinner = document.createElement("div");
    progressbarinner.className = "inner";

    // Now we set the animation parameters
    progressbarinner.style.animationDuration = duration;

    // Eventually couple a callback
    if (typeof callback === "function") {
      progressbarinner.addEventListener("animationend", callback);
    }

    // Append the progressbar to the main progressbardiv
    progressbar.appendChild(progressbarinner);

    // When everything is set up we start the animation
    progressbarinner.style.animationPlayState = "running";
  }

  if (pin) {
    viewPin = true;
  } else {
    viewNormal = true;
  }

  async function resolveResponse(responseData, secretPassword){

    setDecryptPassword(secretPassword);

    if(!responseData.details && !responseData.details.secret){
      throw new Error("Error");
    }

    
    let secretMessage = "";

    if (responseData.details.type != "file") {

      let b64dData = window.atob(responseData.details.secret, "base64");

      //comes from iOS mobile
      if (!isJson(b64dData)) {
        let combined = base64ToArrayBuffer(responseData.details.secret);
        combined = new Uint8Array(combined);
        secretMessage = await decrypt(secretPassword, combined);
        setSecretData(secretMessage);
      } else {
        console.log(secretMessage);
        throw new Error("Error");
      }
    }

    showOwnerLink(false);

    setData(responseData);
    setView(responseData.details.type);

    createProgressbar(
      "sharepass-progressbar-timer",
      "60s",
      function () {
        cleanState();
        window.location.href = envConfig.redirectUrl;
      }
    );

    return true;
  }

  async function RetrieveSecret(
    e = null,
    functionCleanUp = null,
    pinCode = null
  ) {
    if (functionCleanUp) {
      functionCleanUp(true);
    }

    if (document.getElementById("pleaseWait")) {
      document.getElementById("pleaseWait").style.display = "block";
    }

    let currentContent;

    if (e) {
      currentContent = e.target.innerHTML;
      e.target.disabled = true;
      e.target.innerHTML = '<i class="fas fa-circle-notch fa-spin"></i> WAIT';
    }

    let secretPassword = decodeURIComponent(password);

    let headers = {
      "Content-Type": "application/json",
    };

    if (pin && pinCode) {
      headers.pin = pinCode;
    }

    try {
      fetch(FrontendConfig.api_endpoint + "/secret/view?secretId=" + fullId, {
        method: "GET", // or 'PUT'
        headers: headers,
      })
        .then((response) => response.json())
        .then(async (responseData) => {
          if (responseData.status === "Success") {
            try {
              //If returns a security key challenge it needs to be resolved
              if(responseData.details && responseData.details.allowedCredentials){
                let allowedCreds = [];

                for (let i = 0, size = responseData.details.allowedCredentials.length; i < size; i++) {
                  allowedCreds.push({
                    id: b64decode(responseData.details.allowedCredentials[i]["id"]),
                    type: "public-key",
                    transports: responseData.details.allowedCredentials[i]["transports"],
                  });
                }
                //----------get creds from security key or platform authenticator
                const signinOptions = {
                  challenge: b64decode(responseData.details.challenge),
                  timeout: 1800000,
                  rpId: FrontendConfig.rp_id,
                  userVerification: "discouraged",
                  allowCredentials: allowedCreds,
                };

                let cred = {};
                try {
                  //get sign in credentials from authenticator
                  cred = await navigator.credentials.get({
                    publicKey: signinOptions,
                  });

                } catch (err) {
                  console.log("ERR", err);
                  throw {customError: <>
                    <div>
                      <i className="fas fa-3x fa-exclamation-triangle"></i>
                    </div>
                     <p>Unfortunately, your authentication attempt with the security key has failed.</p></>}
                }
                console.log("CRED!", cred);
                //prepare credentials challenge response
                if (cred.response && cred.id) {
                  const clientDataJSON = b64encode(cred.response.clientDataJSON);
                  const authenticatorData = b64encode(
                    cred.response.authenticatorData
                  );
                  const signature = b64encode(cred.response.signature);

                  let enc = new TextEncoder();
                  headers.challengeresponse = b64encode(enc.encode(JSON.stringify({
                    clientDataJSON:clientDataJSON,
                    authenticatorData:authenticatorData,
                    signature:signature,
                    credId: cred.id
                  })));
                }

                
                fetch(FrontendConfig.api_endpoint + "/secret/view?secretId=" + fullId, {
                  method: "GET", // or 'PUT'
                  headers: headers,
                })
                  .then((response) => response.json())
                  .then(async (responseData) => {
                    if (responseData.status === "Success") {
                      
                        let res = await resolveResponse(responseData, secretPassword).catch(() => {return false;}).then((response) => {return response});
                        
                        if(!res){
                          toggleShowModal(
                            <>
                              <div>
                                <i className="fas fa-3x fa-exclamation-triangle"></i>
                              </div>
                              <p>Unfortunately, your authentication attempt with the security key has failed.</p>
                            </>
                          );
                        }
                    } else if (
                      responseData.status !== "Success" &&
                      responseData.message
                    ) {
                      toggleShowModal(
                        <>
                          <div>
                            <i className="fas fa-3x fa-exclamation-triangle"></i>
                          </div>
                          <p> {responseData.message} </p>
                        </>
                      );
                    } else {
                      toggleShowModal(
                        <>
                          <div>
                            <i className="fas fa-3x fa-exclamation-triangle"></i>
                          </div>
                          <p>Whoops! We could not find your secret.</p>
                        </>
                      );
                    }
                  });
              } else {
                await resolveResponse(responseData, secretPassword);
              }
            } catch (error) {
              console.log(error);
              let errorMessage = 
                 error.customError ? error.customError : <>
                  <div>
                    <i className="fas fa-3x fa-lock"></i>
                  </div>
                   <p>
                    Whoops! Unfortunately your secret could not be decrypted.
                    This is probably due to an altered link. Please contact the
                    sender and request a new link.
                  </p>
                </>
              ;

              if (error.type == "custom") {
                errorMessage = (
                  <>
                    <div>
                      <i className="fas fa-3x fa-exclamation-triangle"></i>
                    </div>
                    <p> {error.message} </p>
                  </>
                );
              }

              toggleShowModal(errorMessage);
            }
          } else if (
            responseData.status !== "Success" &&
            responseData.message
          ) {
            toggleShowModal(
              <>
                <div>
                  <i className="fas fa-3x fa-exclamation-triangle"></i>
                </div>
                <p> {responseData.message} </p>
              </>
            );
          } else {
            toggleShowModal(
              <>
                <div>
                  <i className="fas fa-3x fa-exclamation-triangle"></i>
                </div>
                <p>Whoops! We could not find your secret.</p>
              </>
            );
          }

          if (functionCleanUp) {
            functionCleanUp(false);
          }

          if (e) {
            e.target.disabled = false;
            e.target.innerHTML = currentContent;
          }

          if (pinRef.current) {
            pinRef.current.inputs.forEach((input) => {
              input.disabled = false;
              input.value = "";
            });
          }

          if (document.getElementById("pleaseWait")) {
            document.getElementById("pleaseWait").style.display = "none";
          }
        });
    } catch (err) {
      if (functionCleanUp) {
        functionCleanUp(false);
      }

      if (e) {
        e.target.disabled = false;
        e.target.innerHTML = currentContent;
      }

      if (pinRef.current) {
        pinRef.current.inputs.forEach((input) => {
          input.disabled = false;
          input.value = "";
        });
      }

      if (document.getElementById("pleaseWait")) {
        document.getElementById("pleaseWait").style.display = "none";
      }

      let errorMessage = (
        <>
          <div>
            <i className="fas fa-3x fa-lock"></i>
          </div>
          <p>
            Whoops! Unfortunately your secret could not be decrypted. This is
            probably due to an altered link. Please contact the sender and
            request a new link.
          </p>
        </>
      );

      toggleShowModal(errorMessage);
    }
  }

  return (
    !view ?
    <>
      <PINCode
        show={viewPin}
        type={typePIN}
        fields={6}
        retreiveFunction={RetrieveSecret}
      />
      <NormalView show={viewNormal} retreiveFunction={RetrieveSecret} />

      <div className="form-group text-center">
        <div className="mt-2 mb-2">
          <button
            type="button"
            id="qrRevealBtn"
            className="btn btn-link form-control"
            data-toggle="collapse"
            data-target="#qrcodelinkWrapper"
            aria-expanded="false"
            aria-controls="qrcodelinkWrapper"
          >
            <i className="fas fa-share-square"></i> Open in another device
          </button>
        </div>

        <div
          id="qrcodelinkWrapper"
          className="input-group collapse text-center"
        >
          <div className="text-center w100">
            <p className="text-center">
              Scan this QR code to open this same page in another device.
            </p>
          </div>

          <div style={{ width: "100%" }}>
            <QRCode
              value={window.location.href}
              size="250"
              logoImage={envConfig.qrLogo}
              logoWidth="40"
            />
          </div>
        </div>
      </div>
      <div
        className="form-group text-center privacy-policy-block"
        style={{ color: "#4a4a4a" }}
      >
        {pin == "pin" || pin == "dpin" ? "By entering the PIN, you agree on our" : "By clicking 'VIEW', you agree on our" } <br />
        <a
          className="mt-7px"
          href="https://sharepass.com/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
      </div>
      {envConfig.createAccount && <div
        className="form-group text-center privacy-policy-block"
        style={{ color: "#4a4a4a" }}
      >
        <a
          className="mt-7px"
          href="https://app.sharepass.com/auth/register"
          target="_blank"
          rel="noreferrer"
        >
          CREATE ACCOUNT
        </a>
      </div>}
    </>
    :
    (fullId && view && data && <>

      {(view == "message" || view == "QR") && resolveMessageLinkResponse(
        data.details,
        secretData,
        fullId
      )}

      {(view == "credentials") && resolveCredentialsLinkResponse(
        data.details,
        secretData,
        fullId
      )}
      {(view == "password") && resolvePasswordLinkResponse(
        data.details,
        secretData,
        fullId
      )}

      {(view == "custom") && resolveCustomLinkResponse(
        data.details,
        secretData,
        fullId
      )}
      
      {(view == "file") && resolveFileResponse(
        data,
        fullId,
        decryptPassword,
        toggleShowModal
      )}
    </>)
  );
}

const tryRequire = () => {
	try {
		const env = FrontendConfig.env;
		return require("../envs/" + env + "/components/ViewDetails");
	} catch (err) {
		return null;
	}
}


const ViewDetails = tryRequire() ? tryRequire().default : ViewDetailsDefault;

export default ViewDetails;
