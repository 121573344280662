import React, { useRef, useState } from "react";
import ReactCodeInput from "react-code-input";

function PINCode({show, type, fields, retreiveFunction}){

    const [disabledPin, setDisabledPin] = useState(false);
    const [value, setValue] = useState('');
    const codeRef = useRef(undefined);


    function functionCleanUp(bool){
        setDisabledPin(bool);
        setValue('1');

        if(codeRef.current.textInput[0]) codeRef.current.textInput[0].focus();
        codeRef.current.state.input[0] = "";
        codeRef.current.state.input[1] = "";
        codeRef.current.state.input[2] = "";
        codeRef.current.state.input[3] = "";
        codeRef.current.state.input[4] = "";
        codeRef.current.state.input[5] = "";

        let reactPINInput = document.getElementsByClassName('react-code-input');

        if(reactPINInput.length){
            let inputsPIN = reactPINInput[0].getElementsByTagName('input');

            for(let i = 0; i < inputsPIN.length; i++){
                
                if(i === 0){
                    inputsPIN[i].focus();
                }
                
                inputsPIN[i].value = '';
            }
        }
        
        
    }

    function onPinChange(pin){

        if(pin.length == 6){
            setDisabledPin(true);
            retreiveFunction(null, functionCleanUp, pin);
        }
    }

    const filters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    //filterChars={filters} filterCharsIsWhitelist={false}

    if(show){
        return (<>
            <div className="mt-3"></div>
            <div className="text-center">
                <div className="form-group d-i-block">
                    <ReactCodeInput inputMode="numeric" ref={codeRef} value={value} disabled={disabledPin} onChange={onPinChange} type={type} fields={fields}  />
                </div>
            </div>
            <div style={{display:"none"}} id="pleaseWait" className="form-group text-center">
                <label className="text-primary"><i className="fas fa-circle-notch fa-spin"></i> PLEASE WAIT</label>
            </div>
        </>);
    }else{
        return (<></>);
    }
}

export default PINCode;