import React, { useState } from "react";
import {
  base64ToArrayBuffer,
  decrypt,
} from "../Utils";

function FileTemplate({ opened, description, secret, when, secretPassword, filename, toggleShowModal }) {

  let whenBlock = "";
  let warningBlock = "";

  const [downloadDisable, setDownloadDisable] = useState(false);
  const [downloadBtnContent, setDownloadBtnContent] = useState(<>DOWNLOAD</>);

  if (when === false) {
    whenBlock = "";
  } else if (when) {
    whenBlock = (
      <div className="mt-3 mb-3">
        <div className="alert alert-danger" role="alert">
          <i className="fas fa-exclamation-triangle"></i> This link will be
          deleted automatically {when}, save its content in a secure place.
        </div>
      </div>
    );

    warningBlock = (
      <small>
        <div className="text-danger text-center mt-3" role="alert">
          <i className="fas fa-exclamation-triangle"></i> You will need to
          re-enter the link once the progress bar runs out.
        </div>
      </small>
    );
  } else {
    whenBlock = (
      <div className="mt-3 mb-3">
        <div className="alert alert-danger" role="alert">
          <i className="fas fa-exclamation-triangle"></i> This link cannot be
          used again. Please, save its content in a secure place.
        </div>
      </div>
    );

    warningBlock = (
      <small>
        <div className="text-danger text-center mt-3" role="alert">
          <i className="fas fa-exclamation-triangle"></i> This secret will
          expire once the progress bar runs out.
        </div>
      </small>
    );
  }
  const saveDownloadedData = (fileName, data) => {
    console.log("DATA: ", data, data.length);
    const link = document.createElement('a')
    link.setAttribute('target', '_blank');
    link.setAttribute('href', data);

    ~window.navigator.userAgent.indexOf('Edge')
        && (fileName = fileName.replace(/[&\/\\#,+$~%.'':*?<>{}]/g, '_')); /* Edge */

    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const retreiveFunction = async () => {
      setDownloadDisable(true);
      setDownloadBtnContent(<><i className="fas fa-circle-notch fa-spin"></i> DOWNLOADING</>);
      fetch(secret, {
        method: "GET",
      })
      .then((response) => response.body)
      .then(async (body) => {
        const reader = body.getReader();

        return new ReadableStream({
          start(controller) {
            return pump();

            function pump() {
              return reader.read().then(({ done, value }) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  controller.close();
                  return;
                }

                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                return pump();
              });
            }
          }
        })
      })
      .then((stream) => {
        return new Response(stream, { headers: { 'Content-Type': 'text/plain' } }).text();
      })
      .then(async (result) => {
        // Do things with result
        let combined = base64ToArrayBuffer(result);
        combined = new Uint8Array(combined);
        try{
          let secretMessage = await decrypt(secretPassword, combined);
          saveDownloadedData(filename, secretMessage);
        }catch(error){
          console.log("Error decrypting file: ", error);
          toggleShowModal(<>
            <div>
              <i className="fas fa-3x fa-lock"></i>
            </div>
             <p>
              Whoops! Unfortunately your secret could not be decrypted.
              This is probably due to an altered link. Please contact the
              sender and request a new link.
            </p>
          </>);
        }

        setDownloadDisable(false);
        setDownloadBtnContent(<>DOWNLOAD</>);
      })
      .catch((err) => {
        console.error("Err: ",err);
        setDownloadDisable(false);
        setDownloadBtnContent(<>DOWNLOAD</>);
      });
  };

  return (
    <>
      {opened}
      {description}
      <div className="mb-3">
        <i className="fas fa-check-circle"></i> You can reveal the secret delivered.
      </div>

      <div className="text-center mt-3">
        <button disabled={downloadDisable} className="btn btn-primary" onClick={(e) => retreiveFunction(e)}>{downloadBtnContent}</button>
      </div>
      
      <small>
        <div className="text-danger text-center mt-3" role="alert">
          <i className="fas fa-exclamation-triangle"></i> We don't know the content of the file given our Zero trust policy, 
          so make sure you trust the sender or scan the file properly with your antivirus after download.
        </div>
      </small>

      {whenBlock}
      <div id="sharepass-progressbar-timer"></div>
      {warningBlock}
    </>
  );
}

export default FileTemplate;
