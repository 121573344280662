import React, { useState } from "react";

function MessageTemplate({ opened, description, secret, when, rows }) {
  const [textToCopy, setTextToCopy] = useState(secret);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnCopyContent, setBtnCopyContent] = useState(
    <>
      <i className="fas fa-copy"></i> Copy
    </>
  );
  const [btnRevealContent, setBtnRevealContent] = useState(
    <>
      <i className="fas fa-eye"></i> Reveal
    </>
  );
  const [contentRevealed, setContentRevealed] = useState(false);

  function revealData() {
    setContentRevealed(!contentRevealed);
    if (contentRevealed == false) {
      setBtnRevealContent(
        <>
          <i className="fas fa-eye-slash"></i> Hide
        </>
      );
    } else {
      setBtnRevealContent(
        <>
          <i className="fas fa-eye"></i> Reveal
        </>
      );
    }
  }

  function copyToClipboard() {
    navigator.clipboard.writeText(textToCopy);
    setBtnDisabled(true);

    /* Alert the copied text */
    setBtnCopyContent("Copied!");

    setTimeout(function () {
      setBtnDisabled(false);
      setBtnCopyContent(
        <>
          <i className="fas fa-copy"></i> Copy
        </>
      );
    }, 3000);
  }

  let whenBlock = "";
  let warningBlock = "";

  if (when === false) {
    whenBlock = "";
  } else if (when) {
    whenBlock = (
      <div className="mt-3 mb-3">
        <div className="alert alert-danger" role="alert">
          <i className="fas fa-exclamation-triangle"></i> This link will be
          deleted automatically {when}, save its content in a secure place.
        </div>
      </div>
    );

    warningBlock = (
      <small>
        <div className="text-danger text-center mt-3" role="alert">
          <i className="fas fa-exclamation-triangle"></i> You will need to
          re-enter the link once the progress bar runs out.
        </div>
      </small>
    );
  } else {
    whenBlock = (
      <div className="mt-3 mb-3">
        <div className="alert alert-danger" role="alert">
          <i className="fas fa-exclamation-triangle"></i> This link cannot be
          used again. Please, save its content in a secure place.
        </div>
      </div>
    );

    warningBlock = (
      <small>
        <div className="text-danger text-center mt-3" role="alert">
          <i className="fas fa-exclamation-triangle"></i> This secret will
          expire once the progress bar runs out.
        </div>
      </small>
    );
  }

  return (
    <>
      {opened}
      {description}

      <div className="mb-3">
        <i className="fas fa-check-circle"></i> You can reveal the secret
        message delivered.
      </div>
      <div className="mb-3">
        <div className="row">
          <div className="col-6">
            <button
              id="showHideMessageBtn"
              onClick={revealData}
              className="btn btn-primary form-control"
              type="button"
              data-toggle="collapse"
              data-target="#collapseMessage"
              aria-expanded="false"
              aria-controls="collapseMessage"
            >
              {btnRevealContent}
            </button>
          </div>

          <div className="col-6">
            <button
              className="btn form-control btn-outline-primary"
              type="button"
              disabled={btnDisabled}
              onClick={copyToClipboard}
            >
              {btnCopyContent}
            </button>
          </div>
        </div>
      </div>

      <div id="collapseMessage" className="input-group collapse">
        <textarea
          className="form-control"
          defaultValue={secret}
          readOnly
          rows={rows}
        ></textarea>
      </div>

      {whenBlock}
      <div id="sharepass-progressbar-timer"></div>
      {warningBlock}
    </>
  );
}

export default MessageTemplate;
