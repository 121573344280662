/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
// used for making the prop types of this component
import PropTypes from "prop-types";

const Footer = (props) => {
    return (
        <footer className={"footer" + (props.default ? " footer-default" : "")}>
            <div className="container">
                <ul className="nav">
                    <li className="nav-item">
                        <a
                            target="_blank"
                            className="nav-link"
                            href="https://www.centrevision.com.au/"
                        >
                            Centrevision
                        </a>
                    </li>{" "}
                    
                </ul>

                <div className="copyright">
                    Powered by <a target="_blank"
                            className="nav-link"
                            href="https://sharepass.com/">SharePass</a> © {new Date().getFullYear()}
                </div>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    default: PropTypes.bool,
    fluid: PropTypes.bool,
};

export default Footer;
