function OwnerLink({url, show}){

    if(!show){
        return (<></>);
    }

    return (
        <div id="ownerClickBack">
            <small>Owner of the secret? <a href={url} target="_blank">Find it in your portal</a></small>
        </div>
    );
}

export default OwnerLink;