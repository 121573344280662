import React, { Fragment, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import logo from '../../logo.svg';
//import './App.css';
import ViewDetails from '../../components/ViewDetails';
import IncompleteURL from '../../components/templates/IncompleteURL';
import FrontendConfig from "../../assets/config.js";
import Footer from "./components/Footer.js";
import OwnerLink from '../../components/templates/OwnerLink';
import {
	HashRouter as Router,
	Routes,
	Route
} from "react-router-dom";

function App() {
	const [showLink, setShowLink] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [errorModal, setErrorModal] = useState("");

	function toggleShowModal(error = ''){
		setShowModal(!showModal);
		setErrorModal(error);
	};
	function toggleModal(){
		setShowModal(!showModal);
	};
	
	const secretPart = window.location.pathname.replace(/\//g,'');
	const currentPath = window.location.hash.split('/');
	let ownerLink;
	if(currentPath.length == 3 || currentPath.length == 4){
		const urlSet = `${FrontendConfig.site_url}admin/secret/list?show_details=${secretPart+currentPath[1]}`;

		ownerLink = <OwnerLink  url={urlSet} show={showLink} />;
	}

	return (
		<>
			
			<header className="masthead ymt-masthead">
				<div className="container">

					<div className="row h-100">
						<div className="offset-lg-3 col-lg-6 overall">
							<div className="cmxform common-form">
								<div className="text-center mb-3">
									<img src={require("./assets/img/ymtech.png")} width="30%" alt="YMtech logo" title="YMtech logo" />
								</div>

								<div id="responseWrapperView">
									<Router>
										<Fragment>
											<Routes>
												<Route path="/:id/:password" element={<ViewDetails secretPart={secretPart} toggleShowModal={toggleShowModal} showOwnerLink={setShowLink} />}></Route>
												<Route path="/:id/:password/:pin" element={<ViewDetails secretPart={secretPart} toggleShowModal={toggleShowModal} showOwnerLink={setShowLink} />}></Route>
												<Route path="*" element={<IncompleteURL />}></Route>
											</Routes>
										</Fragment>
									</Router>
								</div>
								{ownerLink}
							</div>
						</div>
					</div>
				</div>
				<Footer/>
			</header>
			<Modal show={showModal}>
				<Modal.Header className="alert-danger">
					<h5 className="modal-title">Error</h5>
				</Modal.Header>
				<Modal.Body className="text-center">
					{errorModal}
				</Modal.Body>
				<Modal.Footer>
					<div id="modal-footer-buttons" className="col-md-12 col-sm-12 ">
						<Button className="btn btn-primary close-btn fa-pull-right" onClick={toggleModal} >Close</Button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default App;
