import React, { useState } from "react";

function PasswordTemplate({ opened, description, secret, when }) {
  const [inputType, setInputType] = useState("password");
  const [textToCopy, setTextToCopy] = useState(secret);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnCopyContent, setBtnCopyContent] = useState(
    <i className="fas fa-copy"></i>
  );

  function revealData() {
    if (inputType == "password") {
      setInputType("text");
    } else {
      setInputType("password");
    }
  }

  function copyToClipboard() {
    navigator.clipboard.writeText(textToCopy);
    setBtnDisabled(true);

    /* Alert the copied text */
    setBtnCopyContent("Copied!");

    setTimeout(function () {
      setBtnDisabled(false);
      setBtnCopyContent(<i className="fas fa-copy"></i>);
    }, 3000);
  }

  let whenBlock = "";
  let warningBlock = "";

  if (when === false) {
    whenBlock = "";
  } else if (when) {
    whenBlock = (
      <div className="mt-3 mb-3">
        <div className="alert alert-danger" role="alert">
          <i className="fas fa-exclamation-triangle"></i> This link will be
          deleted automatically {when}, save its content in a secure place.
        </div>
      </div>
    );

    warningBlock = (
      <small>
        <div className="text-danger text-center mt-3" role="alert">
          <i className="fas fa-exclamation-triangle"></i> You will need to
          re-enter the link once the progress bar runs out.
        </div>
      </small>
    );
  } else {
    whenBlock = (
      <div className="mt-3 mb-3">
        <div className="alert alert-danger" role="alert">
          <i className="fas fa-exclamation-triangle"></i> This link cannot be
          used again. Please, save its content in a secure place.
        </div>
      </div>
    );

    warningBlock = (
      <small>
        <div className="text-danger text-center mt-3" role="alert">
          <i className="fas fa-exclamation-triangle"></i> This secret will
          expire once the progress bar runs out.
        </div>
      </small>
    );
  }

  return (
    <>
      {opened}
      {description}
      <div className="mb-3">
        <i className="fas fa-check-circle"></i> You can reveal the secret
        password delivered.
      </div>
      <div className="input-group">
        <div className="input-group-prepend">
          <button
            className="btn btn-outline-primary"
            type="button"
            onClick={revealData}
          >
            <i className="fas fa-eye"></i>
          </button>
        </div>
        <input
          type={inputType}
          className="form-control"
          readOnly
          value={secret}
        />
        <div className="input-group-append">
          <button
            className="btn btn-outline-primary"
            type="button"
            disabled={btnDisabled}
            onClick={copyToClipboard}
          >
            {btnCopyContent}
          </button>
        </div>
      </div>

      {whenBlock}
      <div id="sharepass-progressbar-timer"></div>
      {warningBlock}
    </>
  );
}

export default PasswordTemplate;
