function NormalView({show, retreiveFunction}){

    if(show){
        return (<div className="form-group text-center">
            <button className="btn btn-primary" onClick={(e) => retreiveFunction(e)}>VIEW</button>
        </div>);
    }else{
        return (<></>);
    }
}

export default NormalView;