
const VideoWrapper = () => {
  return (
    <video playsInline muted loop="loop" autoPlay="autoplay" poster="https://aushp.com.au/wp-content/uploads/2022/08/video.jpg" id="bg-video">
        <source src="https://aushp.com.au/wp-content/uploads/2022/08/video.mp4" type="video/mp4" />
    </video>
  );
};

export default VideoWrapper;
