
const VideoWrapper = () => {
  return (
    <video playsInline muted loop="loop" autoPlay="autoplay" id="bg-video">
        <source src="https://www.schur.com/media/no4heo3t/total-solution-provider.mp4" type="video/mp4" />
    </video>
  );
};

export default VideoWrapper;
